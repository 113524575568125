import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

NarrowPageHeaderComponent.propTypes = {
  siteTitle: PropTypes.string,
  headerImageUrl: PropTypes.string,
};

NarrowPageHeaderComponent.defaultProps = {
  siteTitle: '',
};

function NarrowPageHeaderComponent({ headerTitle, headerImageUrl }) {
  const imageSectionStyles = useMemo(() => {
    return headerImageUrl ? { backgroundImage: `url(${headerImageUrl})` } : {};
  }, [headerImageUrl]);

  return (
    <header className="narrow-page-header">
      <div className="regular-page-header-content-wrapper">
        <h1 className="header">
          {headerTitle}
        </h1>
      </div>
      <div
        className="narrow-page-header-art"
        style={imageSectionStyles}
      ></div>
    </header>
  );
}

const NarrowPageHeader = React.memo(NarrowPageHeaderComponent);

export default NarrowPageHeader;
