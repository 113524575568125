import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { UqAppBarV2, UqLayoutV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import SEO from '../components/seo';
import UqLayoutLegalPage from '../components/uqLayoutLegalPage';

import * as styles from './legal.module.scss';

export default function LegalPrivacyPage() {
  return (
    <UqLayoutV2>
      <UqAppBarV2
        className={styles.appbar}
        static
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.root}>
        <div className="master-container legal-privacy-page">
          <UqLayoutLegalPage
            pageInfo={{
              pageName: 'Legal',
              headerTitle: 'Legal',
              headerSubtitle: '',
              headerInfo: '',
              active: 'privacy',
            }}
            headerRowClass="legal-page"
          >
            <SEO title="Legal - Legal unitQ terms of use" />
            <Container className="text-center content-wrapper">
              <Row className="mt-5 mb-4">
                <Col className="layout-col align-center">
                  <div className="legal-content">
                    <p className="c0">
                      <span className="c01">Effective date: February 12th 2024</span>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        We at unitQ know you care about how your personal information is used and shared, and we take
                        your privacy seriously. Please read the following to learn more about our Privacy Policy.{' '}
                      </span>
                      <span className="c02 c13">
                        By using or accessing the Services in any manner, you acknowledge that you accept the practices and
                        policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and
                        share your information in the following ways.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        Remember that your use of unitQ&rsquo;s Services is at all
                        times subject to the{' '}
                      </span>
                      <span className="c3">
                        <a className="c6" href="/legal-terms/">
                          Terms of Use
                        </a>
                      </span>
                      <span className="c7">
                        , which incorporates this Privacy Policy. Any terms we use in
                        this Privacy Policy without defining them have the definitions
                        given to them in the{' '}
                      </span>
                      <span className="c3">
                        <a className="c6" href="/legal-terms/">
                          Terms of Use
                        </a>
                      </span>
                      <span className="c01">.</span>
                    </p>
                    <p className="c0">
                      <span className="c4">&nbsp;</span>
                    </p>
                    <p className="c0">
                      <span className="c8">What does this Privacy Policy cover?</span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        This Privacy Policy covers our treatment of personally
                        identifiable information (&ldquo;Personal Information&rdquo;)
                        that we gather when you are accessing or using our Services,
                        but not to the practices of companies we don&rsquo;t own or
                        control, or people that we don&rsquo;t manage. We gather
                        various types of Personal Information from our users, as
                        explained in more detail below, and we use this Personal
                        Information internally in connection with our Services,
                        including to personalize, provide, and improve our services,
                        to allow you to set up a user account and profile, to contact
                        you and allow other users to contact you, to fulfill your
                        requests for certain products and services, and to analyze how
                        you use the Services. In certain cases, we may also share some
                        Personal Information with third parties, but only as described
                        below.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c7">As noted in the </span>
                      <span className="c3">
                        <a className="c6" href="/legal-terms/">
                          Terms of Use
                        </a>
                      </span>
                      <span className="c7">
                        , we do not knowingly collect or solicit personal information
                        from anyone under the age of 13. If you are under 13, please
                        do not attempt to register for the Services or send any
                        personal information about yourself to us. If we learn that we
                        have collected personal information from a child under age 13,
                        we will delete that information as quickly as possible. If you
                        believe that a child under 13 may have provided us personal
                        information, please contact us at{' '}
                      </span>
                      <a href="mailto:privacy@unitq.com">privacy@unitq.com</a>
                      <span className="c01">.</span>
                    </p>
                    <p className="c0">
                      <span className="c8">
                        Will unitQ ever change this Privacy Policy?
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        We&rsquo;re constantly trying to improve our Services, so we
                        may need to change this Privacy Policy from time to time as
                        well, but we will alert you to changes by placing a notice on
                        the Services, by sending you an email, and/or by some other
                        means. Please note that if you&rsquo;ve opted not to receive
                        legal notice emails from us (or you haven&rsquo;t provided us
                        with your email address), those legal notices will still
                        govern your use of the Services, and you are still responsible
                        for reading and understanding them. If you use the Services
                        after any changes to the Privacy Policy have been posted, that
                        means you agree to all of the changes.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c8">
                        What Information does unitQ Collect?{' '}
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c02 c11">Information You Provide to Us:</span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        We receive and store any information you knowingly provide to
                        us. For example, through the registration process and/or
                        through your account settings, we may collect Personal
                        Information such as your name, email address, and phone
                        number. Certain information may be required to register with
                        us or to take advantage of some of our features.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        We may communicate with you if you&rsquo;ve provided us the
                        means to do so. For example, if you&rsquo;ve given us your
                        email address, we may send you promotional email offers on
                        behalf of other businesses, or email you about your use of the
                        Services. Also, we may receive a confirmation when you open an
                        email from us. This confirmation helps us make our
                        communications with you more interesting and improve our
                        services. If you do not want to receive communications from
                        us, please indicate your preference by emailing us at{' '}
                      </span>
                      <a href="mailto:privacy@unitq.com">privacy@unitq.com</a>
                      <span className="c01">
                        &nbsp;or by following the opt-out instructions contained in
                        emails from us.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c8">Information Collected Automatically</span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        Whenever you interact with our Services, we automatically
                        receive and record information on our server logs from your
                        browser or device, which may include your IP address,
                        geolocation data, device identification, &ldquo;cookie&rdquo;
                        information, the type of browser and/or device you&rsquo;re
                        using to access our Services, and the page or feature you
                        requested. &ldquo;Cookies&rdquo; are identifiers we transfer
                        to your browser or device that allow us to recognize your
                        browser or device and tell us how and when pages and features
                        in our Services are visited and by how many people. You may be
                        able to change the preferences on your browser or device to
                        prevent or limit your device&rsquo;s acceptance of cookies,
                        but this may prevent you from taking advantage of some of our
                        features. Our advertising partners may also transmit cookies
                        to your browser or device, when you click on ads that appear
                        on the Services. Also, if you click on a link to a third party
                        website or service, such third party may also transmit cookies
                        to you. Again, this Privacy Policy does not cover the use of
                        cookies by any third parties, and we aren&rsquo;t responsible
                        for their privacy policies and practices. Please be aware that
                        cookies placed by third parties may continue to track your
                        activities online even after you have left our Services, and
                        those third parties may not honor &ldquo;Do Not Track&rdquo;
                        requests you have set using your browser or device.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        We may use this data to customize content for you that we
                        think you might like, based on your usage patterns. We may
                        also use it to improve the Services - for example, this data
                        can tell us how often users use a particular feature of the
                        Services, and we can use that knowledge to make the Services
                        interesting to as many users as possible.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c8">
                        Information Collected From Other Websites and Do Not Track
                        Policy
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        Through cookies we place on your browser or device, we may
                        collect information about your online activity after you leave
                        our Services. Just like any other usage information we
                        collect, this information allows us to improve the Services
                        and customize your online experience, and otherwise as
                        described in this Privacy Policy. Your browser may offer you a
                        &ldquo;Do Not Track&rdquo; option, which allows you to signal
                        to operators of websites and web applications and services
                        (including behavioral advertising services) that you do not
                        wish such operators to track certain of your online activities
                        over time and across different websites. Our Services do not
                        support Do Not Track requests at this time, which means that
                        we collect information about your online activity both while
                        you are using the Services and after you leave our Services.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c8">
                        Will unitQ Share Any of the Personal Information it Receives?
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        We may share your Personal Information with third parties as
                        described in this below:
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c02">
                        Information that&rsquo;s been de-identified
                      </span>
                      <span className="c01">
                        . We may de-identify your Personal Information so that you are
                        not identified as an individual, and provide that information
                        to our partners. We may also provide aggregate usage
                        information to our partners (or allow partners to collect that
                        information from you), who may use such information to
                        understand how often and in what ways people use our Services,
                        so that they, too, can provide you with an optimal online
                        experience. However, we never disclose aggregate usage or
                        de-identified information to a partner (or allow a partner to
                        collect such information) in a manner that would identify you
                        as an individual.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c02">Advertisers</span>
                      <span className="c01">
                        : We allow advertisers and/or merchant partners
                        (&ldquo;Advertisers&rdquo;) to choose the demographic
                        information of users who will see their advertisements and/or
                        promotional offers and you agree that we may provide any of
                        the information we have collected from you in non-personally
                        identifiable form to an Advertiser, in order for that
                        Advertiser to select the appropriate audience for those
                        advertisements and/or offers. For example, we might use the
                        fact you are located in San Francisco to show you ads or
                        offers for San Francisco businesses, but we will not tell such
                        businesses who you are. Or, we might allow Advertisers to
                        display their ads to users with similar usage patterns to
                        yours, but we will not disclose usage information to
                        Advertisers except in aggregate form, and not in a manner that
                        would identify you personally. Note that if an advertiser asks
                        us to show an ad to a certain audience or audience segment and
                        you respond to that ad, the advertiser may conclude that you
                        fit the description of the audience they were trying to reach.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c02">Affiliated Businesses</span>
                      <span className="c01">
                        : In certain situations, businesses or third party websites
                        we&rsquo;re affiliated with may sell or provide products or
                        services to you through or in connection with the Services
                        (either alone or jointly with us). You can recognize when an
                        affiliated business is associated with such a transaction or
                        service, and we will share your Personal Information with that
                        affiliated business only to the extent that it is related to
                        such transaction or service. We have no control over the
                        policies and practices of third party websites or businesses
                        as to privacy or anything else, so if you choose to take part
                        in any transaction or service relating to an affiliated
                        website or business, please review all such business&rsquo; or
                        websites&rsquo; policies.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c02">Analytics</span>
                      <span className="c7">
                        : We may use third party analytics service providers, such as
                        Google Analytics, for our business purposes, including but not
                        limited to improving and developing our Services, monitoring
                        and analyzing use of our Services, and increasing the
                        functionality and user-friendliness of our Services. When you
                        use our Services, these analytics service providers may
                        collect and retain some information about you by planting a
                        persistent cookie or identifier on your web browser or device.
                        You may opt out of Google Analytics by visiting{' '}
                      </span>
                      <span className="c3">
                        <a
                          className="c6"
                          href="https://tools.google.com/dlpage/gaoptout/"
                        >
                          https://tools.google.com/dlpage/gaoptout/
                        </a>
                      </span>
                      <span className="c7">
                        &nbsp;Google&rsquo;s ability to use and share information
                        collected by Google Analytics about your use of the Services
                        is restricted by the Google Analytics Terms of Service and the
                        Google Privacy Policy. You may find additional information
                        about Google&rsquo;s ability to use and share information
                        collected by Google Analytics by visiting{' '}
                      </span>
                      <span className="c3">
                        <a
                          className="c6"
                          href="https://policies.google.com/technologies/partner-sites"
                        >
                          policies.google.com/technologies/partner-sites
                        </a>
                      </span>
                      <span className="c01">.</span>
                    </p>
                    <p className="c0">
                      <span className="c02">Our Agents</span>
                      <span className="c01">
                        : We employ other companies and people to perform tasks on our
                        behalf and need to share your information with them to provide
                        products or services to you; for example, we may use a payment
                        processing unitQ to receive and process your credit card
                        transactions for us. Unless we tell you differently, our
                        agents do not have any right to use the Personal Information
                        we share with them beyond what is necessary to assist us.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c02">User Profiles and Submissions</span>
                      <span className="c01">
                        : Certain user profile information, including your name,
                        location, and any content that such user has uploaded to the
                        Services, may be displayed to other users to facilitate user
                        interaction within the Services or address your request for
                        our services. Your account privacy settings may allow you to
                        limit the other users who can see the Personal Information in
                        your user profile and/or what information in your user profile
                        is visible to others. Please remember that any content you
                        voluntarily disclose online in a manner other users can view
                        becomes publicly available, and can be collected and used by
                        anyone. Your user name may also be displayed to other users.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c02">Business Transfers</span>
                      <span className="c01">
                        : We may choose to buy or sell assets, and may share and/or
                        transfer customer information in connection with the
                        evaluation of and entry into such transactions. Also, if we
                        (or our assets) are acquired, or if we go out of business,
                        enter bankruptcy, or go through some other change of control,
                        Personal Information could be one of the assets transferred to
                        or acquired by a third party.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c02">Protection of unitQ and Others</span>
                      <span className="c01">
                        : We reserve the right to access, read, preserve, and disclose
                        any information that we believe is necessary to comply with
                        law or court order; enforce or apply our Terms of Use and
                        other agreements; or protect the rights, property, or safety
                        of unitQ, our employees, our users, or others.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c8">
                        Is Personal Information about me secure?
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        Your account is protected by a password for your privacy and
                        security. You must prevent unauthorized access to your account
                        and Personal Information by selecting and protecting your
                        password appropriately and limiting access to your computer or
                        device and browser by signing off after you have finished
                        accessing your account.We endeavor to protect the privacy of
                        your account and other Personal Information we hold in our
                        records, but unfortunately, we cannot guarantee complete
                        security. Unauthorized entry or use, hardware or software
                        failure, and other factors, may compromise the security of
                        user information at any time.
                      </span>
                    </p>
                    <p className="c0">
                      <ul>
                        <li className="c7">
                          <p>
                            <span className="c8">Security Measures. </span>
                            We take steps to ensure that your Personal Information is treated
                            securely and in accordance with this Privacy Policy. Our security program includes
                            administrative, technical and physical safeguards appropriate for our size and resources and
                            the types of information that we process. We cannot guarantee that your Personal Information
                            will be 100% secure. We do not accept liability for unintentional disclosure and you
                            acknowledge that we are not responsible for any intercepted information sent via the internet.
                            By using our Sites or providing Personal Information to us, you agree that we may
                            communicate with you electronically regarding security, privacy, and administrative issues
                            relating to your use of our Sites. If we learn of a security system’s breach, we may attempt to
                            notify you electronically by posting a notice on the Site or sending an e-mail to you. You
                            may have a legal right to receive this notice in writing.
                          </p>
                        </li>
                        <li className="c7">
                          <p>
                            <span className="c8">Retention. </span>
                            We retain the Personal Information we process on behalf of our Subscribers for as
                            long as needed to provide services to our Subscribers or as necessary to fulfill the purpose(s)
                            for which it was collected. We will retain and use this Personal Information as necessary to
                            comply with our legal obligations, resolve disputes, and enforce our agreements.
                          </p>
                        </li>
                        <li className="c7">
                          <p>
                            <span className="c8">Data Transfers. </span>
                            All Personal Information We collect may be stored anywhere in the world,
                            including but not limited to, in the United States, in the cloud, on our servers, or on affiliate
                            or third-party service provider servers. Law enforcement or other authorities may access your
                            Personal Information pursuant to a lawful request. By providing information to Us, you
                            consent to the storage of your Personal Information in these locations.
                          </p>
                        </li>
                        <li className="c7">
                          <p>
                            <span className="c8">Human Resources Data. </span>
                            With regard to Personal Information that We receive in connection
                            with the employment relationship, We will use such Personal Information only for
                            employment-related purposes. If We intend to use this Personal Information for any other
                            purpose, We will notify the individual and provide an opportunity to opt out of such uses.
                            Our use of Personal Information received in connection with employment-related purposes is
                            governed by Our internal policies.
                          </p>
                        </li>
                        <li className="c7">
                          <p>
                            <span className="c8">International Users. </span>
                            By using the Sites, We will transfer data to the United States. By
                            choosing to visit the Sites or otherwise providing information to us, you agree that any
                            dispute over privacy or the terms contained in this Privacy Policy will be governed by the
                            laws of the State of California and the adjudication of any disputes arising in connection with
                            Us or the Sites will be in accordance with the Terms. If you are visiting from the European
                            Union or other regions with laws governing data collection and use, please note that you are
                            agreeing to the transfer of your information to the United States and processing globally. By
                            providing your Personal Information, you consent to any transfer and processing in
                            accordance with this Policy.
                          </p>
                        </li>
                      </ul>
                    </p>
                    <p className="c0">
                      <span className="c8">
                        What Personal Information can I access?
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        {' '}
                        Through your account settings or by contacting{' '}
                      </span>
                      <a href="mailto:privacy@unitq.com">privacy@unitq.com</a>
                      <span className="c01">
                        , you may access, and, in some cases, edit or delete the
                        following information you&rsquo;ve provided to us:
                      </span>
                    </p>
                    <p className="c0">
                      <ul>
                        <li className="c7">
                          <p>name and password</p>
                        </li>
                        <li className="c7">
                          <p>email address </p>
                        </li>
                        <li className="c7">
                          <p>user profile information, including content you have uploaded to the site</p>
                        </li>
                      </ul>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        The information you can view, update, and delete may change as
                        the Services change. If you have any questions about viewing
                        or updating information we have on file about you, please
                        contact us at <a href="mailto:privacy@unitq.com">privacy@unitq.com</a>
                      </span>
                      <span className="c01">:</span>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        Under California Civil Code Sections 1798.83-1798.84,
                        California residents are entitled to contact us to prevent
                        disclosure of Personal Information to third parties for such
                        third parties&rsquo; direct marketing purposes; in order to
                        submit such a request, please contact us at{' '}
                      </span>
                      <a href="mailto:privacy@unitq.com">privacy@unitq.com</a>
                      <span className="c01">:</span>
                    </p>
                    <p className="c0">
                      <span className="c8">What choices do I have?</span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        You can always opt not to disclose information to us, but keep
                        in mind some information may be needed to register with us or
                        to take advantage of some of our features.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        You may be able to add, update, or delete information as
                        explained above. When you update information, however, we may
                        maintain a copy of the unrevised information in our records.
                        You may request deletion of your account by contacting{' '}
                      </span>
                      <a href="mailto:privacy@unitq.com">privacy@unitq.com</a>
                      <span className="c01">
                        . Some information may remain in our records after your
                        deletion of such information from your account. We may use any
                        aggregated data derived from or incorporating your Personal
                        Information after you update or delete it, but not in a manner
                        that would identify you personally.
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c8">Retention</span>
                    </p>
                    <p className="c0">
                      <span className="c01">
                        unitQ retains the Personal Information we process on behalf of you for as long as needed to
                        provide services to you or as necessary to fulfill the purpose(s) for which it was collected.
                        We will retain and use this Personal Information as necessary to comply with our legal obligations,
                        resolve disputes, and enforce our agreements. Where unitQ is a data controller,
                        we will retain your Personal Information in accordance with Data Protection Legislation and for as long as necessary
                        to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting,
                        or reporting requirements. To determine the appropriate retention period for Personal Information,
                        we consider the amount, nature, and sensitivity of the Personal Information,
                        the potential risk of harm from unauthorized use or disclosure of your Personal Information,
                        the purposes for which we process your Personal Information, and whether we can achieve those purposes
                        through other means. In some circumstances we may anonymize your Personal Information
                        (so that it can no longer be associated with you).
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c8">
                        What if I have questions about this policy?
                      </span>
                    </p>
                    <p className="c0">
                      <span className="c7">
                        If you have any questions or concerns regarding our privacy
                        policies, please send us a detailed message to{' '}
                      </span>
                      <a href="mailto:privacy@unitq.com">privacy@unitq.com</a>
                      <span className="c01">
                        , and we will try to resolve your concerns.
                      </span>
                    </p>
                    <p className="c12">
                      <span className="c4"></span>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col>
                  <Container className="legal-content eu-representative-container">
                    <Row className="eu-representative-header">
                      <Col>
                        <b>Name:</b> unitQ inc.
                      </Col>
                    </Row>
                    <Row>
                      <Col className="eu-representative-content">
                        <b>Contact information for designated EU representative:</b>
                        <br />
                        DP-Dock GmbH <br />
                        unitQ inc. <br />
                        Ballindamm 39 <br />
                        20095 Hamburg <br />
                        Germany
                        <br />
                        Fax: +49(0) 40 99999 - 3332
                        <br />
                        Email: <a href="mailto:unitq@gdpr-rep.com">unitq@gdpr-rep.com</a>
                        <br />
                        <a
                          href="http://www.dp-dock.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.dp-dock.com
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </UqLayoutLegalPage>
        </div>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
