import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Container } from 'react-bootstrap';

const LegalNavbar = ({ activeItem }) => (
  <div className="TOPOFNAVBARCOMPONENT">
    <Container className="legal-navbar" style={{ maxWidth: 'unset' }}>
      <Row>
        <Col className="legal-navbar-spacer-col"></Col>
        <Col
          className={
            activeItem === 'security'
              ? 'legal-navbar-col active'
              : 'legal-navbar-col'
          }
        >
          <Link to="/legal-security/" className="legal-navbar-link">
            Security
          </Link>
        </Col>
        <Col
          className={
            activeItem === 'terms'
              ? 'legal-navbar-col active'
              : 'legal-navbar-col'
          }
        >
          <Link to="/legal-terms/" className="legal-navbar-link">
            <nobr>Terms of Use</nobr>
          </Link>
        </Col>
        <div className="w-100"></div>
        <Col
          className={
            activeItem === 'privacy'
              ? 'legal-navbar-col active'
              : 'legal-navbar-col'
          }
        >
          <Link to="/legal-privacy/" className="legal-navbar-link">
            <nobr>Privacy Policy</nobr>
          </Link>
        </Col>
        <Col
          className={
            activeItem === 'copyright'
              ? 'legal-navbar-col active'
              : 'legal-navbar-col'
          }
        >
          <Link to="/legal-copyright/" className="legal-navbar-link">
            <nobr>Copyright Dispute Policy</nobr>
          </Link>
        </Col>
        <Col className="legal-navbar-spacer-col"></Col>
      </Row>
    </Container>
  </div>
);

export default LegalNavbar;
