/**
 * Layout component for tall header pages
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';

import LegalNavbar from './legalNavBar';
import NarrowPageHeader from './narrowPageHeader';

const UqLayoutLegalPage = ({
  children, pageInfo, pageHeaderColStyles = {}, headerRowClass,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery4 {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Container fluid className="px-0 main master-container">
          <Row
            noGutters
            className={
              classNames(
                'px-0 header-row-background header-row-background-narrow-page justify-content-center',
                { [headerRowClass]: headerRowClass },
              )
            }
          >
            <Col className="page-header-wrapper justify-content-center" style={{ ...pageHeaderColStyles }}>
              <NarrowPageHeader
                siteTitle={data.site.siteMetadata.title}
                headerTitle={pageInfo.headerTitle}
                headerImageUrl={pageInfo.headerImageUrl}
              />
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <LegalNavbar activeItem={pageInfo.active} />
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Container className="content-max-width">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
);

export default UqLayoutLegalPage;
